/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/sections/hero';
import Features from '../components/sections/features';
import Integrations from '../components/sections/integrations';
import CTA from '../components/sections/cta';
import { SEO } from '../components/seo';
import { useTranslation } from 'react-i18next';

const CheckoutPage = () =>
{
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('Less stress, more happiness')}
        description={t('Introducing our audio therapy app, designed to help you achieve a sense of balance and harmony in your life. ')}
      ></SEO>
      <Layout>
      <div className="grid text-slate-200 md:px-20 xl:px-60 md:pt-20 bg-slate-900 min-h-screen md:grid-cols-2">
        <div className="p-20 order-last md:order-first">
          <h1 className="text-3xl mb-3">😴 Fall asleep faster</h1>
          <p className="text-lg">Hypnosis has been shown to help people fall asleep faster through various scientific studies.

          <ul className="list-disc mt-3">
            <li className="mt-3">A 2002 study in the Journal of Clinical Psychology found that listening to a hypnosis recording before bed resulted in faster sleep and better sleep quality compared to not listening to one.</li>
            <li className="mt-3">A 2012 study in the Journal of Sleep Research found that hypnosis was more effective at reducing the time it takes to fall asleep (sleep onset latency) than a sleep education or relaxation program.</li>
            <li className="mt-3">A 2017 meta-analysis in the Journal of Clinical Psychology found that hypnosis had a moderate to large effect on treating insomnia, with an average effect size of 0.57.</li>
        </ul>
          </p>
        </div>
        <div className="p-20 md:order-last order-first">
        <h1 className="text-3xl mb-8">Deep Sleep Session</h1>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div className="bg-primary-400 h-2.5 rounded-full" style={{ width: '45%' }}></div>

          <div className="mt-8 p-8 rounded-lg border-2 border-slate-600 bg-slate-500 hover:border-primary-400 hover:bg-slate-700 cursor-pointer w-full">
            <h3 className="text-lg">Deep Sleep</h3>
            <p>Get access to this hypnosis for $9.99</p>
          </div>

          <div className="mt-8 p-8 rounded-lg border-2 border-slate-600 bg-slate-500 w-full">
          <h3 className="text-lg">Bundle</h3>
            Get access to for $39.99/year
          </div>
      </div>


        </div>
      </div>
      </Layout>
    </>
  );
};

export default CheckoutPage;
